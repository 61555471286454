<template>
  <v-col cols="12" lg="2" class="sticky-sidebar">
    <template v-if="!$vuetify.breakpoint.lgAndDown">
      <v-list
        class="pt-0 sticky-sidebar__item"
        :style="`${
          $vuetify.breakpoint.width < 1400 ? 'max-width: 163px' : null
        }`"
      >
        <active-hosting-plan-selector
          :items="plans"
          :value="activePlan"
          :returnObject="true"
          @change="changeServerAccount"
          itemText="name"
          itemValue="id"
          class="mb-6"
        />

        <v-subheader class="pl-0 p-4 mt-6 pb-3">{{ $t('heading.hosting.sidebar.manage.title') }}</v-subheader>
        <v-list-item-group
          color="primary"
          class="d-flex flex-row flex-lg-column justify-start"
        >
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/hosting/summary`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.hosting.summary.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/hosting/domains`"
            v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_domains')"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.hosting.domains.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/hosting/ftp`"
            v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_ftp')"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.hosting.ftp.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/hosting/databases`"
            v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_mysql')"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">
                {{ $t('heading.hosting.databases.title') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="activePlan.cron_jobs_supported && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_cron')"
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/hosting/cron`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.hosting.cron.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="activePlan.service_dns_server_enabled && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_dns')"
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/hosting/dns`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">
                {{ $t('heading.hosting.dns.title') }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-subheader
          v-if="activePlan.service_email_server_enabled && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_email')"
          class="pl-0 p-4 mt-6 pb-3"
          >{{ $t('heading.hosting.sidebar.email.title') }}</v-subheader
        >
        <v-list-item-group
          v-if="activePlan.service_email_server_enabled && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_email')"
          color="primary"
          :active-class="'elevation-4--before'"
          class="d-flex flex-row flex-lg-column justify-start"
        >
          <v-list-item :ripple="false" :to="`/hosting/email-addresses`">
            <v-list-item-content>
              <v-list-item-title class="p-2">
                {{ $t('heading.hosting.emailAddress.title') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            :active-class="'elevation-4--before'"
            :to="`/hosting/email-forwarders`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">
                {{ $t('heading.hosting.emailForwarders.title') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-else>
      <active-hosting-plan-selector
        :items="plans"
        :value="activePlan"
        :returnObject="true"
        @change="changeServerAccount"
        itemText="name"
        itemValue="id"
        class="mt-2"
      />
      <v-tabs
        :class="$vuetify.breakpoint.mobile ? 'mt-4' : ''"
        center-active
        class="pt-0 d-flex flex-row flex-lg-column justify-start"
        style="overflow: hidden"
      >
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/summary`"
        >
          <span class="p-2">{{ $t('heading.hosting.summary.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/domains`"
          v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_domains')"
        >
          <span class="p-2">{{ $t('heading.hosting.domains.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/ftp`"
          v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_ftp')"
        >
          <span class="p-2">{{ $t('heading.hosting.ftp.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/databases`"
          v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_mysql')"
        >
          <span class="p-2">{{ $t('heading.hosting.databases.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/cron`"
          v-if="activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_cron')"
        >
          <span class="p-2">{{ $t('heading.hosting.cron.title') }}</span>
        </v-tab>
        <v-tab
          v-if="activePlan.service_dns_server_enabled && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_dns')"
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/dns`"
        >
          <span class="p-2">{{ $t('heading.hosting.dns.title') }}</span>
        </v-tab>
        <v-tab
          v-if="activePlan.service_email_server_enabled && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_email')"
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/email-addresses`"
        >
          <span class="p-2">{{ $t('heading.hosting.emailAddress.title') }}</span>
        </v-tab>
        <v-tab
          v-if="activePlan.service_email_server_enabled && activePlan.getUserPrivileges(activePlan.privileges, 'hosting.manage_email')"
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/hosting/email-forwarders`"
        >
          <span class="p-2">{{ $t('heading.hosting.emailForwarders.title') }}</span>
        </v-tab>
      </v-tabs>
    </template>
  </v-col>
</template>

<script>
import ActiveHostingPlanSelector from "./ActiveHostingPlanSelector.vue";

export default {
  components: {
    ActiveHostingPlanSelector,
  },
  props: {
    plans: {
      type: Array,
      default: () => {
        [];
      },
    },
    activePlan: {
      type: Object,
    },
  },
  methods: {
    changeServerAccount: function (item) {
      this.$emit("update:activePlan", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-subheader {
  align-content: flex-start;
  height: 30px;
}
.v-item-group {
  margin-bottom: 24px;
}

.v-list {
  .v-list-item {
    padding-left: 0px;
    transition: padding-left 0.3s ease;
    .v-list-item__content {
      position: relative;
      z-index: 1;
    }
    .v-list-item__title {
      font-weight: $font-weight-semibold;
      color: map-get($text, header);
      transition: color 0.24s ease, font-weight 0.24s ease;
    }
    &::before {
      z-index: 0;
      background: #fff;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &:hover {
      padding-left: 12px;
      .v-list-item__title {
        color: map-get($primary, base);
      }
    }
    &--active {
      padding-left: 12px;
      border-radius: 4px;
      .v-list-item__title {
        font-weight: $font-weight-bold;
        color: map-get($primary, darken1);
      }
      &::before {
        border-radius: 4px;
        opacity: 1 !important;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .v-list-item-group {
      justify-content: flex-start;
    }
    .v-list-item {
      padding: 0 12px 0 12px !important;
      text-align: center;
      min-width: unset !important;
    }
  }
}

.sticky-sidebar {
  position: relative;
}

.sticky-sidebar__item {
  position: sticky;
  padding-left: 0px !important;
  top: 120px;
  left: 0;
}

.v-tabs::v-deep {
  padding: 8px 0 !important;
  margin: 0 -24px;
  overflow: hidden !important;
  width: 100vw;
  .v-item-group {
    background-color: transparent;
  }
  .v-slide-group__wrapper {
    contain: none !important;
    overflow: visible !important;

    max-width: 100%;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
  .v-tab {
    position: relative;
    overflow: visible !important;
    padding: 0 16px;
    border-radius: 4px;
    box-shadow: none;
    transition: opacity 0.3s ease;
    margin-left: 4px;
    margin-right: 4px;
    background: transparent;
    span {
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: map-get($text, heading);
      font-weight: $font-weight-semibold;
    }
    &:not(:first-child) {
      margin-left: 0 !important;
    }

    &--active {
      background: #fff;

      span {
        color: map-get($primary, darken1);
        font-weight: $font-weight-bold;
      }
    }
  }
}

.v-application--is-rtl{
    .v-list {
      .v-list-item {
        padding-right: 0px;
        transition: padding-right 0.3s ease;
        &:hover{
            padding-right: 12px;
        }
        &--active{
            padding-right: 12px;
        }
      }
    }
}
</style>
